import {AvailableField} from './MailyticaSmartResponse';

export class Metadata {

  constructor(
    public metadataFieldId: string,
    public metadataFieldLabel: string,
    public metadataType: string,
    public metadataValue: string) {
  }

  public static fromJson(entity: any): Metadata {

    return new Metadata(
      entity.metadataFieldId,
      entity.metadataFieldLabel,
      entity.metadataType,
      entity.metadataValue
    );
  }

  public static empty(metadataFieldId: string): Metadata {

    return new Metadata(
      metadataFieldId,
      '',
      'String',
      ''
    );
  }

  public toJson(): any {

    const json = {
      metadataFieldId: this.metadataFieldId,
      metadataFieldLabel: this.metadataFieldLabel,
      metadataType: this.metadataType,
      metadataValue: this.metadataValue
    };

    return json;
  }
}

export class AvailableFieldMetadataMapping {

  constructor(public availableField: AvailableField, public metadata: Metadata[], public actValue: string) {

  }

  public static matchAvailableFieldsToMetadata(availableFields: AvailableField[], metadata: Metadata[]): AvailableFieldMetadataMapping[] {

    const availableFieldMetadataMappings: AvailableFieldMetadataMapping[] = [];

    availableFields
      .filter(field => field.displayInClientStatus !== 'inactive')
      .forEach(field => {

        const metadataFields = metadata.filter(md => md.metadataFieldId === field.fieldNameId);
        if (metadataFields.length === 0) {

          metadataFields.push(Metadata.empty(field.fieldNameId));
        }

        metadataFields.forEach(metadataField => {

          // push DropDown-Fields only once to the availableFieldMetadataMappings-Array
          if (field.fieldType === 'StringArray') {

            let duplicate: boolean = false;

            if (metadataField.metadataValue !== '') {

              availableFieldMetadataMappings.forEach(mapping => {
                if (mapping.availableField === field) {
                  duplicate = true;
                  mapping.metadata.push(metadataField);
                }
              });

              if (!duplicate) {

                const tempMetadata: Metadata[] = [];
                tempMetadata.push(metadataField);
                availableFieldMetadataMappings.push(new AvailableFieldMetadataMapping(field, tempMetadata, metadataField.metadataValue));
              }
            }
          } else if (metadataField.metadataFieldId.endsWith('selected')) {

            const availableFieldMetadataMapping = availableFieldMetadataMappings.find(mapping => field.fieldNameId.startsWith(mapping.availableField.fieldNameId));

            if (availableFieldMetadataMappings !== undefined) {

              availableFieldMetadataMapping.actValue = metadataField.metadataValue;
            }

          } else {

            // ignore displayNonEditable-mappings without metadataValue
            if (!(metadataField.metadataValue === '' && field.displayInClientStatus === 'displayNonEditable')) {

              if (field.fieldNameLabel === '') {
                field.fieldNameLabel = field.fieldNameId;
              }

              const tempMetadata: Metadata[] = [];
              tempMetadata.push(metadataField);
              availableFieldMetadataMappings.push(new AvailableFieldMetadataMapping(field, tempMetadata, metadataField.metadataValue.replace('\\r', '')));
            }
          }
        });
      });

    return availableFieldMetadataMappings;
  }

  public metadataSelect(): string[] {

    const values: string[] = new Array<string>();
    values.push[''];

    this.metadata.forEach(md => values.push(md.metadataValue));

    return values;
  }
}
