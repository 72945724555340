<div id="mailytica" *ngIf="!httpErrorResponse">
  <div class="mailyticaContainer">
    <div class="mailyticaContainer" *ngIf="this.mailyticaSmartResponse || this.messageError"
         [ngClass]="{'waiting': loadingAnimation == true && !this.messageError}">
      <div id="smartResponse" *ngIf="!this.messageError">
        <app-topic class="section topic"
                   [topics]="this.mailyticaSmartResponse.topics"
                   [classificationLabel]="this.classificationLabel"
                   (selectedTopicChange)="this.updateSelectedTopic($event)"
                   (reloadMailyticaSmartResponse)="this.classifyDraft()"
        ></app-topic>
        <app-e-mail-template class="section"
                             [smartResponseProposals]="this.mailyticaSmartResponse.smartResponseProposals"
                             [selectedSmartResponseProposal]="this.currentlyDisplayedSmartResponseProposal"
                             (updateSelectedSmartResponseProposal)="this.updateSelectedSmartResponseProposal($event)"
        ></app-e-mail-template>
        <app-proposal class="section"
                      [smartResponseProposal]="this.currentlyDisplayedSmartResponseProposal"
                      [isLocked]="this.mailyticaSmartResponse.isLocked"
                      [styleMaxHeight]="45"
                      (openNewMail)="this.buttonClickedInsertText()"
        ></app-proposal>
      </div>

      <div class="userActions" *ngIf="!this.messageError">
        <button class="ms-Button ms-Button--primary" (click)="this.buttonClickedInsertText()">
          <span class="ms-Button-label">{{'INSERT-TEXT-BUTTON' | translate}}</span>
        </button>
      </div>

      <div class="errorHeadline" *ngIf="this.messageError">
        <i class="ms-Icon ms-Icon--ErrorBadge" aria-hidden="true"></i>
        <div class="ms-font-l errorStatus">{{this.messageError}}</div>
      </div>

      <button *ngIf="this.messageError" class="ms-Button ms-Button--primary" (click)="restart()">
        <span class="ms-Button-label">{{'RELOAD-BUTTON' | translate}}</span>
      </button>
    </div>

    <ng-template #loading class="headline">
      <div class="d-flex justify-content-center">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden ms-font-l">{{'LOADING-DATA' | translate}}</span>
        </div>
      </div>
    </ng-template>

    <div class="loadingForeground" *ngIf="loadingAnimation">
      <div class="d-flex justify-content-center">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden ms-font-l">{{'LOADING-DATA' | translate}}</span>
        </div>
      </div>
      <span class="ms-font-xl loadingText">{{'LOADING-SMART-RESPONSE' | translate}}</span>
    </div>
  </div>
</div>

<div class="error" *ngIf="httpErrorResponse">
  <div class="errorHeadline">
    <i class="ms-Icon ms-Icon--ErrorBadge" aria-hidden="true"></i>
    <div class="ms-font-l errorStatus">{{'ERROR' | translate}}: {{httpErrorResponse.status}}</div>
  </div>

  <div class="errorBody" *ngIf="httpErrorResponse.status == 401">
    <div class="ms-font-s errorText">{{'ERROR-401' | translate}}</div>
  </div>

  <div class="errorBody" *ngIf="httpErrorResponse.status == 0">
    <div class="ms-font-s errorText">{{this.constants.name}}{{'ERROR-0' | translate}}</div>
  </div>

  <button class="ms-Button ms-Button--primary" (click)="restart()">
    <span class="ms-Button-label">{{'RELOAD-BUTTON' | translate}}</span>
  </button>
</div>
