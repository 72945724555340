import {CategoryModel} from '../mailytica-http/AvailableScopesHeader';

export class MailboxMailyticaAccountMapping {

  constructor(
    public mailbox: string,
    public categoryModel: CategoryModel
  ) {
  }

  public static async getCurrentMailbox(): Promise<string> {

    let mailbox: string;

    return new Promise(async (resolve) => {

      try {

        const sharedPropertiesAsync = Office.context.mailbox.item.getSharedPropertiesAsync;

        if (!sharedPropertiesAsync) {

          try {
            //if email compose --> use sender email address
            Office.context.mailbox.item.from.getAsync(sender => {
              resolve(sender.value.emailAddress);
            });

          } catch {
            //private mailbox --> use userProfile email address
            mailbox = Office.context.mailbox.userProfile.emailAddress;
            resolve(Office.context.mailbox.userProfile.emailAddress);
          }

        } else {

          Office.context.mailbox.item.getSharedPropertiesAsync(result => {

            const sharedProperties = result.value;
            mailbox = sharedProperties.targetMailbox;
            resolve(sharedProperties.targetMailbox);
          });
        }
      } catch {
        await new Promise(f => setTimeout(f, 300));
        this.getCurrentMailbox();
      }
    });
  }

  public static async getCurrentEmailAdress(): Promise<string> {

    let emailAdress: string;

    return new Promise(async (resolve) => {
      try {

        const sharedPropertiesAsync = Office.context.mailbox.item.getSharedPropertiesAsync;

        if (!sharedPropertiesAsync) {

          emailAdress = Office.context.mailbox.userProfile.emailAddress;
          resolve(emailAdress);

        } else {

          Office.context.mailbox.item.getSharedPropertiesAsync(result => {

            const sharedProperties = result.value;
            emailAdress = sharedProperties.targetMailbox;
            resolve(sharedProperties.targetMailbox);
          });
        }
      } catch {
        await new Promise(f => setTimeout(f, 300));
        this.getCurrentMailbox();
      }
    });
  }
}
