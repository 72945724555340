import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {AvailableField} from 'src/app/services/mailytica-http/MailyticaSmartResponse';
import {AvailableFieldMetadataMapping, Metadata} from 'src/app/services/mailytica-http/MetaData';
import {ClientSetting} from "../../../../services/mailytica-http/ClientSetting";

@Component({
  selector: 'app-fields',
  templateUrl: './fields.component.html',
  styleUrls: [
    './fields.component.css'
  ]
})
export class FieldsComponent implements OnChanges {

  @Input() availableFields: AvailableField[];
  @Input() metadata: Metadata[];
  @Input() clientSettings: ClientSetting;

  @Output() metaDataChangeEvent: EventEmitter<Metadata[]> = new EventEmitter<Metadata[]>();

  public availableFieldMetadataMapping: AvailableFieldMetadataMapping[] = [];

  ngOnChanges(): void {

    this.availableFieldMetadataMapping = AvailableFieldMetadataMapping.matchAvailableFieldsToMetadata(this.availableFields, this.metadata);

    if (this.clientSettings.finishMessageActivityStatus === 'active' || this.clientSettings.finishMessageBySmartResponseStatus === 'active') {

      const finishedMetadata = this.metadata.filter(md => md.metadataFieldId === 'finished');

      if (finishedMetadata.length > 0) {

        const availableField = new AvailableField('displayNonEditable', 'finished', 'Message finished', 'String');

        this.availableFieldMetadataMapping.push(new AvailableFieldMetadataMapping(availableField, finishedMetadata, finishedMetadata[0].metadataValue));
      }
    }
  }

  public openUrl(availableField: string) {

    this.availableFieldMetadataMapping.forEach(mapping => {

      if (mapping.metadata[0].metadataFieldId === availableField) {
        let url = mapping.actValue;

        if (!url.includes('https://')) {
          url = 'https://' + url;
        }

        window.open(url);
      }
    });
  }

  public selectedDropDownItemChanged(availableField: AvailableField, fieldValue: string) {

    const selectedFieldId = availableField.fieldNameId + '_selected';
    const elementIndex = this.metadata.findIndex(metadata => metadata.metadataFieldId === selectedFieldId);

    if (elementIndex !== -1) {

      const existingMetadata = this.metadata[elementIndex];
      existingMetadata.metadataValue = fieldValue;

      this.metadata[elementIndex] = existingMetadata;

    } else {

      this.metadata.push(new Metadata(selectedFieldId, '', 'String', fieldValue));
    }

    this.availableFieldMetadataMapping.forEach(mapping => {

      if (mapping.availableField.fieldNameId === availableField.fieldNameId) {
        mapping.actValue = fieldValue;
      }
    });

    this.metaDataChangeEvent.emit(this.metadata);
  }

  public metadataChanged(mapping: AvailableFieldMetadataMapping) {

    this.metadata.forEach(metadata => {

      if (mapping.metadata[0].metadataFieldId === metadata.metadataFieldId) {
        metadata.metadataValue = mapping.actValue;
      }
    });

    this.metaDataChangeEvent.emit(this.metadata);
  }
}
