<div *ngIf="this.availableFieldMetadataMapping.length > 0" class="ms-font-l">{{'FIELDS' | translate}}</div>

<div *ngFor="let mapping of this.availableFieldMetadataMapping" class="fields">

  <!-- Label for mappingType Date, DateTime, EuropeanDateTime, String -->
  <div *ngIf="mapping.availableField.fieldType == 'String' ||
    mapping.availableField.fieldType == 'Date' ||
    mapping.availableField.fieldType == 'DateTime' ||
    mapping.availableField.fieldType == 'European DateTime'">

    <!-- editable label, non mandatory -->
    <div *ngIf="mapping.availableField.displayInClientStatus === 'displayEditableNonMandatory'" class="ms-font-m field">
      <div class="ms-TextField">
        <label class="ms-Label">{{mapping.availableField.fieldNameLabel}}</label>
        <input class="ms-TextField-field"
               [(ngModel)]="mapping.actValue"
               (ngModelChange)="this.metadataChanged(mapping)"
               type="text">
      </div>
    </div>

    <!-- editable label, mandatory-->
    <div *ngIf="mapping.availableField.displayInClientStatus === 'displayEditableMandatory'" class="ms-font-m field">
      <div class="ms-TextField">
        <label class="ms-Label">{{mapping.availableField.fieldNameLabel}}*</label>
        <input class="ms-TextField-field"
               [(ngModel)]="mapping.actValue"
               (ngModelChange)="this.metadataChanged(mapping)"
               type="text">
      </div>
    </div>

    <!-- non editable label expandable-->
    <div *ngIf="mapping.availableField.displayInClientStatus === 'displayNonEditable' && mapping.actValue.length > 200"
         class="ms-font-m field">
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="ms-TextField expanded">
              <label class="ms-Label">{{mapping.availableField.fieldNameLabel}}</label>
              <mat-icon>keyboard_arrow_down</mat-icon>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div [innerHTML]="mapping.actValue"></div>
      </mat-expansion-panel>
    </div>
    <!-- non editable label not expandable-->
    <div
      *ngIf="mapping.availableField.displayInClientStatus === 'displayNonEditable' && mapping.actValue.length <= 200"
         class="ms-font-m field">
      <div class="ms-TextField nonEditableNotExpanded">
        <label class="ms-Label labelNonEditableNotExpanded">{{mapping.availableField.fieldNameLabel}}:</label>
        <div [innerHTML]="mapping.actValue"></div>
      </div>
    </div>
  </div>

  <!-- DropDown for fieldType StringArray-->
  <div
    *ngIf="mapping.availableField.fieldType === 'StringArray' && mapping.availableField.displayInClientStatus !== 'inactive'">
    <div>
      {{mapping.availableField.fieldNameLabel}}:
      <!--
      <div class="btn-group btn-block">

      <button class="btn btn-default btn-block dropdown-toggle text-left" type="button" id="dropdownMenuButton"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        {{mapping.actValue}}
      </button>

      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <a *ngFor="let data of mapping.metadata" class="dropdown-item"
           (click)="this.selectedDropDownItemChanged(mapping.availableField, $event)">{{data.metadataValue}}</a>
      </div>

      </div>
          -->

      <ng-select2 class="searchDropDown"
                  [data]="mapping.metadataSelect()"
                  (valueChanged)="this.selectedDropDownItemChanged(mapping.availableField, $event)"
                  [value]="mapping.actValue"
      >
      </ng-select2>
    </div>

  </div>

  <!-- Link for fieldType Link-->
  <div
    *ngIf="mapping.availableField.fieldType == 'Link' && !mapping.actValue.includes('\<a href') && mapping.availableField.displayInClientStatus !== 'inactive'"
    class="ms-font-m field"
    (click)="openUrl(mapping.availableField.fieldNameId)"
  >
    <div class="ms-TextField">
      <label class="ms-Label">{{mapping.availableField.fieldNameLabel}}: <a
        class="ms-Link">{{mapping.actValue}}</a></label>
    </div>
  </div>

  <!-- Link for fieldType Link with <a>-Tag -->
  <div
    *ngIf="mapping.availableField.fieldType == 'Link' && mapping.actValue.includes('\<a href') && mapping.availableField.displayInClientStatus !== 'inactive'"
    class="ms-font-m field"
  >
    <div class="ms-TextField" [innerHTML]="this.mapping.actValue"></div>
  </div>
</div>
