<div class="sectionProposal" [style.maxHeight.vh]="this.styleMaxHeight" *ngIf="this.smartResponseProposal.smartResponse != null">
    <div>

        <div *ngIf="this.smartResponseProposal.topicLabel != 'Smart Assist'"
             class="ms-font-m"
             style="margin-bottom: 15px; font-weight: bold;"
        >
          {{ this.smartResponseProposal.label | translate }}:
        </div>

        <div *ngIf="!isLocked && this.smartResponseProposal.topicLabel != 'Smart Assist'"
             class="ms-font-m textGradient proposal"
            [innerHTML]="this.smartResponseProposal.smartResponse"
            (click)="this.openNewMailEvent()">
        </div>

        <div *ngIf="isLocked && this.smartResponseProposal.topicLabel != 'Smart Assist'" class="ms-font-m textGradientLocked"
            [innerHTML]="this.smartResponseProposal.smartResponse">
        </div>

        <div *ngIf="!isLocked && !this.smartAssistLoadingAnimation && this.smartResponseProposal.topicLabel == 'Smart Assist'" class="ms-font-m changed" (click)="this.openNewMailEvent()">
            <ngx-typed-js
                [strings]="[this.smartResponseProposal.smartResponse]"
                [typeSpeed]="10"
                [showCursor]="false"
                [startDelay]="500">
                <h1 class="typing ms-font-m"></h1>
            </ngx-typed-js>
        </div>

        <div *ngIf="this.smartAssistLoadingAnimation && this.smartResponseProposal.topicLabel == 'Smart Assist'" class="headline">
            <div class="d-flex justify-content-center">
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden ms-font-l">{{'LOADING-DATA' | translate}}</span>
              </div>
            </div>
        </div>
    </div>
</div>
