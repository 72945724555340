import {Metadata} from './MetaData';
import {Attachment} from './MailyticaSmartResponse';

export class SmartResponseProposal {

  constructor(
    public attachments: Attachment[],
    public autoReplyStatus: string,
    public bccRecipient: string,
    public ccRecipient: string,
    public hasAttachments: boolean,
    public id: string,
    public label: string,
    public note: string,
    public receivingAddress: string,
    public salutation: string,
    public salutationStatus: string,
    public smartResponse: string,
    public smartResponseText: string,
    public subject: string,
    public topicLabel: string,
    public forwardReceivedAttachmentsStatus: string
  ) {
  }

  public static fromJson(entity: any): SmartResponseProposal {

    return new SmartResponseProposal(
      entity.attachments.map(attachment => Attachment.fromJson(attachment)),
      entity.autoReplyStatus,
      entity.bccRecipient,
      entity.ccRecipient,
      entity.hasAttachments,
      entity.id,
      entity.label,
      entity.note,
      entity.receivingAddress,
      entity.salutation,
      entity.salutationStatus,
      entity.smartResponse,
      entity.smartResponseText,
      entity.subject,
      entity.topicLabel,
      entity.forwardReceivedAttachmentsStatus
    );
  }

  public checkProposalTextForMetadataVariables(availableMetadata: Metadata[]): Metadata[] {

    const metadataList: Metadata[] = [];

    for (let i = 0; i < this.smartResponse.length - 1; i++) {

      if (this.smartResponse.charAt(i) === '{' && this.smartResponse.includes('}')) {

        const variable = this.smartResponse.substring(i + 1, this.smartResponse.indexOf('}', i));
        const metadata = availableMetadata.find(md => md.metadataFieldId === variable);

        if (metadata !== undefined) {
          metadataList.push(metadata);
        } else {
          metadataList.push(new Metadata(variable, variable, 'String', ''));
        }
      }
    }

    return metadataList;
  }

  public replaceProposalVariables(metadataVariables: Metadata[]): string {
    let newProposalText = this.smartResponse;

    metadataVariables.forEach(variable => {
      newProposalText = newProposalText.replace('{' + variable.metadataFieldId + '}', variable.metadataValue);
    });

    return newProposalText;
  }
}
