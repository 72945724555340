import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {SmartResponseProposal} from '../../../../services/mailytica-http/SmartResponseProposal';

@Component({
  selector: 'app-proposal',
  templateUrl: './proposal.component.html',
  styleUrls: ['./proposal.component.css']
})
export class ProposalComponent implements OnInit {

  @Input() smartResponseProposal: SmartResponseProposal;
  @Input() smartAssistLoadingAnimation: boolean;
  @Input() isLocked: boolean;
  @Input() styleMaxHeight: string;

  @Output() openNewMail: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  async ngOnInit(): Promise<void> {
  }

  async openNewMailEvent() {

    this.openNewMail.emit(true);
  }
}
