import {Metadata} from './MetaData';

export class ExecuteUserActionData {

  constructor(
    public actionId: string,
    public metadata: Metadata[] // not used here anymore, as always all input fields sould be sent (eg for persisting)
  ) {

  }
}
