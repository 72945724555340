import {HttpErrorResponse} from '@angular/common/http';
import {Component, NgZone, OnInit} from '@angular/core';
import {MailyticaSmartResponse} from '../services/mailytica-http/MailyticaSmartResponse';
import {Message} from '../services/mailytica-http/Message';
import {TranslateService} from '@ngx-translate/core';
import {OfficeRoamingSettingsService} from '../services/office-roaming-settings/office-roaming-settings.service';
import {SmartResponseService} from '../services/smartResponse/smart-response.service';
import {MailboxMailyticaAccountMapping} from '../services/office-roaming-settings/MailboxMailyticaAccountMapping';
import {OfficeItemChangedService} from '../services/office-item-changed/office-item-changed.service';
import {Md5} from 'ts-md5';
import {ActivatedRoute} from '@angular/router';
import * as constants from 'src/environments/environment';
import {PredictionMessage} from '../services/mailytica-http/PredictionMessage';
import {NotificationService} from '../services/notification/notification.service';
import {MyUnsubscribeComponent} from '../my-unsubscribe/my-unsubscribe.component';
import {SmartResponseProposal} from "../services/mailytica-http/SmartResponseProposal";

@Component({
  selector: 'app-message-compose',
  templateUrl: './message-compose.component.html',
  styleUrls: ['./message-compose.component.css']
})
export class MessageComposeComponent extends MyUnsubscribeComponent implements OnInit {

  public authorizationValue: string = '';
  public mailboxMailyticaAccountMapping: MailboxMailyticaAccountMapping;
  public loadingAnimation: boolean = false;
  public mailyticaSmartResponse: MailyticaSmartResponse;
  public currentlyDisplayedSmartResponseProposal: SmartResponseProposal;
  public classificationLabel: string = 'Standard';

  private initialEmailText: string;

  public httpErrorResponse: HttpErrorResponse;
  public messageError: string;

  public smartAssistLoadingAnimation: boolean = false;
  public predictionMessage: PredictionMessage = new PredictionMessage(0, '', '', '');

  private mailyticaAPIUrl: string = 'api.mailytica.ai';
  public constants = constants;

  constructor(
    public notificationService: NotificationService,
    private officeService: OfficeRoamingSettingsService,
    public smartResponseService: SmartResponseService,
    public translate: TranslateService,
    private zone: NgZone,
    private officeItemChangedService: OfficeItemChangedService,
    private activatedRoute: ActivatedRoute,
    private officeRoamingSettingsService: OfficeRoamingSettingsService
  ) {
    super();

    translate.addLangs(['en', 'de', 'es']);
    translate.setDefaultLang('en');
    translate.use(officeService.getLanguage());
  }

  ngOnInit(): void {

    this.registerSubscription(
      this.activatedRoute.paramMap.subscribe(paramMap => {

        if (paramMap.get('baseUrl') != null) {
          this.mailyticaAPIUrl = paramMap.get('baseUrl');
        }
      })
    );

    // get optional configuration from manifest
    this.registerSubscription(
      this.activatedRoute.queryParams
        .subscribe(paramMap => {

          if (paramMap.accessToken != null) {
            this.authorizationValue = 'Bearer ' + paramMap.accessToken;
          }
        })
    );

    this.officeItemChangedService.itemSenderChanged();
    if (constants.smartAssist) {
      this.officeItemChangedService.itemBodyChanged();
    }

    // reload add-in when emailItem changed
    this.registerSubscription(
      OfficeItemChangedService.mailItem.subscribe(mailboxMailyticaAccountMapping => {

        this.authorizationValue = '';
        this.activatedRoute
          .queryParams
          .subscribe(paramMap => {

            if (paramMap.accessToken != null) {
              this.authorizationValue = 'Bearer ' + paramMap.accessToken;
            }
          });

        this.mailboxMailyticaAccountMapping = mailboxMailyticaAccountMapping;

        this.zone.run(async () => {
          this.initialize();
        });
      })
    );

    // check user input for smartAssist
    this.registerSubscription(
      OfficeItemChangedService.itemBody.subscribe(async isWriting => {
        if (this.mailyticaSmartResponse) {
          const inputCursorPosition = await this.officeItemChangedService.getInputCursorPosition();
          const inputMessage = this.officeItemChangedService.getInputMessage();

          await this.updatePredictionMessage(inputCursorPosition, inputMessage);
          this.updateSelectedSmartResponseProposalForClassification('Smart Assist');
        }
      })
    );

    // check recipients periodical
    this.registerSubscription(
      OfficeItemChangedService.itemSender.subscribe(sender => {

        if (this.authorizationValue !== undefined && this.authorizationValue !== '') {
          this.classifyDraft();
        }
      })
    );

    this.initialize();
  }

  private async initialize() {

    const mailbox = await MailboxMailyticaAccountMapping.getCurrentMailbox();
    const categoryModel = this.officeRoamingSettingsService.getCategoryModel(mailbox);
    this.mailboxMailyticaAccountMapping = new MailboxMailyticaAccountMapping(mailbox, categoryModel);

    // if authorization was not set in manifest --> try to get authorizationValue from roamingSettings;
    if (this.authorizationValue === '') {

      this.authorizationValue = this.officeRoamingSettingsService.getMailboxAuthorizationValueMapping(this.mailboxMailyticaAccountMapping.mailbox.toLowerCase());
    }

    if (this.authorizationValue !== undefined) {

      await this.classifyDraft();

    } else {

      this.translate
        .get('EMAIL-DRAFT-NO-ACCESS-TOKEN')
        .subscribe(translation => this.messageError = translation);
    }
  }

  public async classifyDraft() {

    this.loadingAnimation = true;

    if (this.mailboxMailyticaAccountMapping.categoryModel) {

      const message = await Message.getDraft(this.draftId());

      if (message.sender) {
        this.messageError = undefined;
        this.initialEmailText = this.getHashValue(await Message.getBodyText());

        this.smartResponseService.classifyDraft(message, this.mailyticaAPIUrl, this.authorizationValue).subscribe((data) => {
          this.mailyticaSmartResponse = MailyticaSmartResponse.fromJson(data);
          this.classificationLabel = this.mailyticaSmartResponse.classification.label;
          this.updateSelectedSmartResponseProposalForClassification(this.mailyticaSmartResponse.classification.label);
          this.mailyticaSmartResponse.addSmartAssistProposal();

          this.loadingAnimation = false;
        }, error => this.httpErrorResponse = error);
      } else {
        this.loadingAnimation = false;
        this.translate.get('EMAIL-DRAFT-RECIPIENT-NOT-SET').subscribe(translation => this.messageError = translation);
      }
    }
  }

  private draftId(): string {

    const draftId = Office.context.mailbox.item.conversationId;

    if (draftId) {

      return draftId;
    } else {

      return this.generateQuickGuid();
    }
  }

  private generateQuickGuid() { // FIXME

    return Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
  }

  public async updateSelectedTopic(selectedTopic: string) {

    this.loadingAnimation = true;

    const message = await Message.getDraft(this.draftId());

    const subscription = this.smartResponseService
      .updateSelectedTopic(message, selectedTopic, this.mailyticaAPIUrl, this.authorizationValue)
      .subscribe(
        (mailyticaSmartResponse) => {

          this.mailyticaSmartResponse = mailyticaSmartResponse;

          this.classificationLabel = this.mailyticaSmartResponse.classification.label;
          this.mailyticaSmartResponse.addSmartAssistProposal();
          this.updateSelectedSmartResponseProposalForClassification(this.classificationLabel);

          this.loadingAnimation = false;

          this.notificationService.notify('FINISH-MESSAGE-RESPONSE');
        },
        (error) => console.error(error)
      );

    this.registerSubscription(subscription);
  }

  public updateSelectedSmartResponseProposal(selectedSmartResponseProposal: SmartResponseProposal) {
    this.currentlyDisplayedSmartResponseProposal = selectedSmartResponseProposal;
  }

  public updateSelectedSmartResponseProposalForClassification(topicLabel: string) {
    //take first smartResponseProposal with same topic as classification
    this.currentlyDisplayedSmartResponseProposal = this.mailyticaSmartResponse
      .smartResponseProposals
      .find(proposal => proposal.topicLabel === topicLabel);

    //if no smartResponseProposal with same topic as classification was found
    if (this.currentlyDisplayedSmartResponseProposal === undefined) {
      this.currentlyDisplayedSmartResponseProposal = this.mailyticaSmartResponse.smartResponseProposals[0];
    }
  }

  public async updatePredictionMessage(inputCursorPosition: number, inputMessage: string) {

    const inputWords: string[] = inputMessage.trim().split(' ');
    let smartResponse: string = '';

    //start sending prediction calls from 3 words
    if (inputWords.length >= 3) {
      this.smartAssistLoadingAnimation = true;

      //send max the last 10 written words
      if (inputWords.length > 10) {

        const lastWrittenWords = inputWords.slice(inputWords.length - 10, inputWords.length).join(' ');
        this.predictionMessage = PredictionMessage.fromJson(await this.smartResponseService.predictMessage(lastWrittenWords, inputCursorPosition, this.mailyticaAPIUrl, this.authorizationValue));
      } else {
        this.predictionMessage = PredictionMessage.fromJson(await this.smartResponseService.predictMessage(inputMessage, inputCursorPosition, this.mailyticaAPIUrl, this.authorizationValue));
      }

      this.smartAssistLoadingAnimation = false;
      smartResponse = this.predictionMessage.prediction;

      if (smartResponse === '') {
        this.translate.get('PREDICTION-NOT-AVAILABLE').subscribe(translation => smartResponse = translation);
      }
    } else {
      this.translate.get('WAITING-FOR-INPUT').subscribe(translation => smartResponse = translation);
    }

    this.mailyticaSmartResponse.setSmartAssistPredictionMessage(smartResponse);
  }

  public buttonClickedInsertText() {
    if (this.currentlyDisplayedSmartResponseProposal.topicLabel === 'Smart Assist') {
      this.insertPredictionMessage();
    } else {
      this.insertSmartResponseProposal();
    }
  }

  public async insertSmartResponseProposal() {

    const currentEmailText = await Message.getBodyText();

    if (this.compareInitalHashValueWithCurrentHashValue(this.getHashValue(currentEmailText)) || currentEmailText === '" "') {
      Office.context.mailbox.item.body.setSelectedDataAsync(
        this.currentlyDisplayedSmartResponseProposal.smartResponse,
        {coercionType: Office.CoercionType.Html});
    } else {
      Office.context.mailbox.item.body.setSelectedDataAsync(
        '<br><br>' + this.currentlyDisplayedSmartResponseProposal.smartResponseText + '<br>',
        {coercionType: Office.CoercionType.Html});
    }

    if (this.currentlyDisplayedSmartResponseProposal.hasAttachments) {
      this.currentlyDisplayedSmartResponseProposal.attachments
        .forEach(async attachment => {
          if (!await Message.attachmentAlreadyExist(attachment)) {
            const accessToken = this.authorizationValue.replace('Bearer ', '');
            Message.addFileAttachment(attachment, accessToken);
          }
        });
    }
  }

  public insertPredictionMessage() {
    Office.context.mailbox.item.body.setSelectedDataAsync(
      this.predictionMessage.prediction,
      {coercionType: Office.CoercionType.Html});

  }

  private getHashValue(text: string): string {
    text = Md5.hashStr(text);
    return text;
  }

  private compareInitalHashValueWithCurrentHashValue(currentEmailText: string): boolean {
    if (this.initialEmailText === currentEmailText) {
      return true;
    } else {
      return false;
    }
  }

  restart() {
    window.location.reload();
  }
}

