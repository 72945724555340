import {Injectable} from '@angular/core';

import {MailyticaSmartResponse} from 'src/app/services/mailytica-http/MailyticaSmartResponse';
import {Message} from '../mailytica-http/Message';

import {MailyticaHttpService} from 'src/app/services/mailytica-http/mailyticaHttp.service';
import {AccountService} from '../account/account.service';
import {ExecuteUserActionData} from '../mailytica-http/ExecuteUserActionData';
import {Observable} from 'rxjs';
import {SmartResponseProposal} from "../mailytica-http/SmartResponseProposal";


@Injectable({
  providedIn: 'root'
})
export class SmartResponseService {

  constructor(private mailyticaHttpService: MailyticaHttpService) {
  }

  public classifyMessage(message: string, mailyticaAPIUrl: string, authorizationValue: string): Observable<MailyticaSmartResponse> {
    return this.mailyticaHttpService.getSmartResponses(message, mailyticaAPIUrl, authorizationValue);
  }

  public updateSelectedTopic(message: Message, newTopic: string, mailyticaAPIUrl: string, authorizationValue: string): Observable<MailyticaSmartResponse> {

    return this.mailyticaHttpService.sendGoldStandardClassification(message, newTopic, mailyticaAPIUrl, authorizationValue);
  }

  public consumeSmartResponse(mailyticaSmartResponse: MailyticaSmartResponse, smartResponseProposal: SmartResponseProposal, mailyticaAPIUrl: string, authorizationValue: string): Observable<string> {

    return this.mailyticaHttpService.consumeSmartResponse(mailyticaSmartResponse.messageId, smartResponseProposal.id, mailyticaAPIUrl, authorizationValue);
  }

  public finishMessage(message: Message, mailyticaAPIUrl: string, authorizationValue: string): Observable<MailyticaSmartResponse> {

    return this.mailyticaHttpService.finishMessage(message, mailyticaAPIUrl, authorizationValue);
  }

  public changeSalutation(message: Message, salutation: string, mailyticaAPIUrl: string, authorizationValue: string): Observable<MailyticaSmartResponse> {

    return this.mailyticaHttpService.persistCustomerSalutation(message, salutation, mailyticaAPIUrl, authorizationValue);
  }

  public changeLanguage(message: Message, language: string, mailyticaAPIUrl: string, authorizationValue: string): Observable<MailyticaSmartResponse> {

    return this.mailyticaHttpService.persistCustomerLanguage(message, AccountService.convertLanguageToIsoCode(language), mailyticaAPIUrl, authorizationValue);
  }

  public executeUserAction(executeUserActionData: ExecuteUserActionData, message: Message, mailyticaAPIUrl: string, authorizationValue: string): Observable<MailyticaSmartResponse> {

    return this.mailyticaHttpService.postUserAction(message, executeUserActionData.actionId, mailyticaAPIUrl, authorizationValue);
  }

  public classifyDraft(message: Message, mailyticaAPIUrl: string, authorizationValue: string): Observable<MailyticaSmartResponse> {

    return this.mailyticaHttpService.getDraftSmartResponses(message, mailyticaAPIUrl, authorizationValue);
  }

  public async predictMessage(inputMessage: string, inputCursorPosition: number, mailyticaAPIUrl: string, authorizationValue: string) {

    return await this.mailyticaHttpService.predictMessage(inputMessage, inputCursorPosition, mailyticaAPIUrl, authorizationValue);
  }
}
