import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MailyticaSmartResponseGUIComponent } from './mailytica/mailytica-SmartResponseGUI/mailytica-SmartResponseGUI.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SettingsComponent } from './mailytica/settings/settings.component';
import { FormsModule } from '@angular/forms';
import { NavigationBarComponent } from './navigationBar/navigationBar.component';
import { LoginComponent } from './mailytica/settings/login/login.component';
import { ProposalComponent } from './mailytica/mailytica-SmartResponseGUI/smartResponse/proposal/proposal.component';
import { TopicComponent } from './mailytica/mailytica-SmartResponseGUI/smartResponse/topic/topic.component';
import { EMailTemplateComponent } from './mailytica/mailytica-SmartResponseGUI/smartResponse/e-mail-template/e-mail-template.component';
import { SalutationModalComponent } from './mailytica/mailytica-SmartResponseGUI/actions/salutation-modal/salutation-modal.component';
import { LanguageModalComponent } from './mailytica/mailytica-SmartResponseGUI/actions/language-modal/language-modal.component';
import { FieldsComponent } from './mailytica/mailytica-SmartResponseGUI/smartResponse/fields/fields.component';
import { UserActionsComponent } from './mailytica/mailytica-SmartResponseGUI/actions/availableUserActions/user-actions.component';
import { MetadataModalComponent } from './mailytica/mailytica-SmartResponseGUI/actions/metadata-modal/metadata-modal.component';
import { OfficeRoamingSettingsService } from './services/office-roaming-settings/office-roaming-settings.service';
import {NgSelect2Module} from 'ng-select2';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { InternalNoteComponent } from './mailytica/mailytica-SmartResponseGUI/smartResponse/internal-note/internal-note.component';
import { MessageComposeComponent } from './message-compose/message-compose.component';
import { NgxTypedJsModule } from 'ngx-typed-js';
import {MyUnsubscribeComponent} from "./my-unsubscribe/my-unsubscribe.component";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    MailyticaSmartResponseGUIComponent,
    SettingsComponent,
    NavigationBarComponent,
    LoginComponent,
    ProposalComponent,
    TopicComponent,
    EMailTemplateComponent,
    SalutationModalComponent,
    LanguageModalComponent,
    FieldsComponent,
    UserActionsComponent,
    MetadataModalComponent,
    InternalNoteComponent,
    MessageComposeComponent,
    MyUnsubscribeComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MatExpansionModule,
    MatIconModule,
    MatFormFieldModule,
    MatDialogModule,
    MatSnackBarModule,
    FormsModule,
    NgSelect2Module,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    NgxTypedJsModule
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {provide: MatDialogRef, useValue: {}},
    {provide: MAT_DIALOG_DATA, useValue: {}},
    OfficeRoamingSettingsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
