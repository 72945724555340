import { EventEmitter, Injectable } from '@angular/core';
import { MailboxMailyticaAccountMapping } from '../office-roaming-settings/MailboxMailyticaAccountMapping';
import { MailyticaHttpService } from '../mailytica-http/mailyticaHttp.service';
import { Message } from '../mailytica-http/Message';
import { OfficeRoamingSettingsService } from '../office-roaming-settings/office-roaming-settings.service';

@Injectable({
  providedIn: 'root'
})
export class OfficeItemChangedService {
  public static mailItem: EventEmitter<MailboxMailyticaAccountMapping> = new EventEmitter<MailboxMailyticaAccountMapping>();
  public static itemBody: EventEmitter<boolean> = new EventEmitter<boolean>();
  public static itemSender: EventEmitter<string> = new EventEmitter<string>();

  private initialText: string = "";
  //private currentText: string = "";
  private currentText: string;
  private currentCursorPosition: number = 0;
  private lastIsWritingValue: boolean = false;

  constructor(
    private mailyticaHttpService: MailyticaHttpService,
    private officeRoamingSettingsService: OfficeRoamingSettingsService
  ) { 
    Office.context.mailbox.addHandlerAsync(Office.EventType.ItemChanged, () => this.mailItemChanged());
  }

  private async mailItemChanged(){
    let mailbox = await MailboxMailyticaAccountMapping.getCurrentMailbox();
    let categoryModel = this.officeRoamingSettingsService.getCategoryModel(mailbox);
    OfficeItemChangedService.mailItem.emit(new MailboxMailyticaAccountMapping(mailbox, categoryModel));
  }

  public async itemBodyChanged(){
    this.initialText = await Message.getBodyText();
    await new Promise(f => setTimeout(f, 50));
    
    let currentIsWritingValue = await this.isWriting();

    if(this.lastIsWritingValue && !currentIsWritingValue){
      OfficeItemChangedService.itemBody.emit(currentIsWritingValue);
    }

    this.lastIsWritingValue = currentIsWritingValue;
    this.itemBodyChanged();
  }

  public async itemSenderChanged(){
    //recipients and sender are swapped by classifyDraft
    let initialSender: string = "";
    let currentSender: string = "";

    try{
      initialSender = (await Message.getRecipients()).map(recipient => recipient.emailAddress)[0];
      await new Promise(f => setTimeout(f, 1000));
      currentSender = (await Message.getRecipients()).map(recipient => recipient.emailAddress)[0];
    } catch(error) {
      console.log(error);
    }

    if(initialSender != currentSender){
      OfficeItemChangedService.itemSender.emit(currentSender);
    }

    this.itemSenderChanged();
  }

  private async isWriting(): Promise<boolean>{
    let currentText = await Message.getBodyText();
    let currentCursorPosition = await this.getInputCursorPosition();
    
    if(currentText){
      if(currentText === this.initialText && (currentText.charAt(currentCursorPosition) == ' ' || currentText.charAt(currentCursorPosition) == '.' || currentText.charAt(currentCursorPosition) == '?' || currentText.charAt(currentCursorPosition) == '!') || currentText.charAt(currentCursorPosition) == ','){
        return false;
      }
      else{
        return true;
      } 
    }
    else{
      return false;
    }
  }

  public async getInputCursorPosition(): Promise<number>{
    let inputMessage = await Message.getBodyText();

    if(inputMessage){
      if(inputMessage != this.initialText){
        if(inputMessage.length >= this.initialText.length){
          this.currentCursorPosition = this.getIndexOfFirstDifference(inputMessage, this.initialText, 0, this.initialText.length);
        }
        else{
          this.currentCursorPosition = this.getIndexOfFirstDifference(inputMessage, this.initialText, 0, inputMessage.length);
        }
        
        this.currentText = inputMessage.substring(0, this.currentCursorPosition + 1);
      }
    }
    
    return this.currentCursorPosition;
  }

  public getInputMessage(): string{
    return this.currentText;
  }

  private getIndexOfFirstDifference(currentText: string, initialText: string, startSearchIndex: number, endSearchIndex: number): number{
    if(startSearchIndex == endSearchIndex || (endSearchIndex - startSearchIndex) == 1){
      if(currentText[startSearchIndex] == initialText[startSearchIndex]){
        return endSearchIndex;
      }
      else{
        return startSearchIndex;
      }
    }

    let currentTextSubstring = currentText.substring(startSearchIndex, endSearchIndex);
    let initialTextSubstring = initialText.substring(startSearchIndex, endSearchIndex);

    if(currentTextSubstring != initialTextSubstring){
      return this.getIndexOfFirstDifference(currentText, initialText, startSearchIndex, Math.ceil((endSearchIndex - startSearchIndex) / 2) + startSearchIndex);
    } else{
      if(currentText.length >= this.initialText.length){
        return this.getIndexOfFirstDifference(currentText, initialText, endSearchIndex, initialText.length);
      }
      else{
        return this.getIndexOfFirstDifference(currentText, initialText, endSearchIndex, currentText.length);
      }
    }
  }
}