export class PredictionMessage {
    constructor(
        public inputCursorPosition: number,
        public inputMessage: string,
        public languageModelId: string,
        public prediction: string
    ){
    }

    public static fromJson(entity: any): PredictionMessage{
        return new PredictionMessage(
            entity.inputCursorPosition,
            entity.inputMessage,
            entity.languageModelId,
            entity.prediction
        );
    }
}
