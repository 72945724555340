import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {SortedSmartResponseProposals} from 'src/app/services/mailytica-http/SortedSmartResponseProposals';
import {SmartResponseProposal} from '../../../../services/mailytica-http/SmartResponseProposal';

@Component({
  selector: 'app-e-mail-template',
  templateUrl: './e-mail-template.component.html',
  styleUrls: ['./e-mail-template.component.css']
})
export class EMailTemplateComponent implements OnChanges {

  @Input() smartResponseProposals: SmartResponseProposal[];
  @Input() selectedSmartResponseProposal: SmartResponseProposal;

  @Output() updateSelectedSmartResponseProposal: EventEmitter<SmartResponseProposal> = new EventEmitter<SmartResponseProposal>();
  @Output() showSmartAssist: EventEmitter<boolean> = new EventEmitter<boolean>();

  sortedSmartResponseProposals: SortedSmartResponseProposals[] = new Array();

  ngOnChanges() {

    this.sortedSmartResponseProposals = SortedSmartResponseProposals.sortSmartResponseProposals(this.smartResponseProposals);
  }

  changeSelectedSmartResponseProposal(selectedSmartResponseProposal: SmartResponseProposal) {

    this.selectedSmartResponseProposal = selectedSmartResponseProposal;
    this.updateSelectedSmartResponseProposal.emit(selectedSmartResponseProposal);
    this.showSmartAssist.emit(false);
  }
}
