import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {first} from 'rxjs/operators';
import {zip} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private snackBar: MatSnackBar,
    private translateService: TranslateService
  ) {
  }

  notify(messageKey: string, durationMillis: number = 1000): void {

    const dismissObservable = this.translateService
      .get('DISMISS')
      .pipe(first());

    const messageObservable = this.translateService
      .get(messageKey)
      .pipe(first());

    zip(dismissObservable, messageObservable)
      .pipe(first())
      .subscribe(result => {

        const [dismiss, message] = result;

        this.snackBar.open(message, dismiss, { duration: durationMillis });
      });
  }
}
