import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

import {Message} from './Message';
import {MailyticaSmartResponse} from './MailyticaSmartResponse';
import {AvailableScopesHeader, CategoryModel} from './AvailableScopesHeader';
import {AccessToken, LoginRequest} from './LoginCredentials';

import {OfficeRoamingSettingsService} from 'src/app/services/office-roaming-settings/office-roaming-settings.service';
import {PredictionMessage} from './PredictionMessage';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MailyticaHttpService {

  constructor(
    private httpClient: HttpClient,
    private officeRoamingSettingsService: OfficeRoamingSettingsService
  ) {
  }

  // public async getMailboxMailyticaAccountMapping(): Promise<MailboxMailyticaAccountMapping>{
  //   let mailbox = await MailboxMailyticaAccountMapping.getCurrentMailbox();

  //   //this.authorizationValue = this.officeRoamingSettingsService.getMailboxAuthorizationValueMapping(mailbox);
  //   let categoryModel = this.officeRoamingSettingsService.getCategoryModel(mailbox);

  //   if(this.authorizationValue !== undefined){
  //     this.mailboxMailyticaAccountMapping = new MailboxMailyticaAccountMapping(mailbox, categoryModel);
  //   }
  //   else{
  //     this.mailboxMailyticaAccountMapping = new MailboxMailyticaAccountMapping(mailbox, undefined);
  //   }

  //   return this.mailboxMailyticaAccountMapping;
  // }

  // private setAccessToken(acesstoken: string) {
  //   this.accessToken = acesstoken;
  // }

  // public setAuthorizationValue(authorizationValue: string){
  //   this.authorizationValue = authorizationValue;
  // }

  // public getAuthorizationValue(): string{
  //   return this.authorizationValue;
  // }

  public getSmartResponses(
    messageJson: string,
    mailyticaAPIUrl: string,
    authorizationValue: string
  ): Observable<MailyticaSmartResponse> {

    const url = 'https://' + mailyticaAPIUrl + '/api/classifyMessage';

    const body = messageJson;

    const options = {
      headers: this.smartResponseHeaderToJson(authorizationValue)
    };

    return this.httpClient
      .post<string>(url, body, options)
      .pipe(map(responseJson => MailyticaSmartResponse.fromJson(responseJson)));
  }

  public sendGoldStandardClassification(
    message: Message,
    goldStandardLabel: string,
    mailyticaAPIUrl: string,
    authorizationValue: string
  ): Observable<MailyticaSmartResponse> {

    const url = 'https://' + mailyticaAPIUrl + '/api/goldStandardClassification';

    const body = this.goldStandardClassificationPayloadToJson(message, goldStandardLabel);

    const options = {
      headers: this.smartResponseHeaderToJson(authorizationValue)
    };

    return this.httpClient
      .post<string>(url, body, options)
      .pipe(map(responseJson => MailyticaSmartResponse.fromJson(responseJson)));
  }

  public consumeSmartResponse(
    messageId: string,
    smartResponseProposalsId: string,
    mailyticaAPIUrl: string,
    authorizationValue: string
  ): Observable<string> {

    const url = 'https://' + mailyticaAPIUrl + '/api/consumeSmartResponse';

    const body = {
      emailId: messageId,
      smartResponseProposalId: smartResponseProposalsId
    };

    const options = {
      headers: this.smartResponseHeaderToJson(authorizationValue)
    };

    return this.httpClient
      .post<string>(url, body, options);
  }

  public finishMessage(
    message: Message,
    mailyticaAPIUrl: string,
    authorizationValue: string
  ): Observable<MailyticaSmartResponse> {

    const url = 'https://' + mailyticaAPIUrl + '/api/finishMessage';

    const body = message.fallbackMessagetoJson();

    const options = {
      headers: this.smartResponseHeaderToJson(authorizationValue)
    };

    return this.httpClient
      .post<string>(url, body, options)
      .pipe(map(responseJson => MailyticaSmartResponse.fromJson(responseJson)));
  }

  public persistCustomerSalutation(
    message: Message,
    salutation: string,
    mailyticaAPIUrl: string,
    authorizationValue: string
  ): Observable<MailyticaSmartResponse> {

    const url = 'https://' + mailyticaAPIUrl + '/api/persistCustomerSalutation';

    const body = {
      salutation: salutation,
      message: message
    };

    const options = {
      headers: this.smartResponseHeaderToJson(authorizationValue)
    };

    return this.httpClient
      .post<string>(url, body, options)
      .pipe(map(responseJson => MailyticaSmartResponse.fromJson(responseJson)));
  }

  public persistCustomerLanguage(
    message: Message,
    language: string,
    mailyticaAPIUrl: string,
    authorizationValue: string
  ): Observable<MailyticaSmartResponse> {

    const url = 'https://' + mailyticaAPIUrl + '/api/persistCustomerLanguage';

    const body = {
      language: language,
      message: message
    };

    const options = {
      headers: this.smartResponseHeaderToJson(authorizationValue)
    };

    return this.httpClient
      .post<string>(url, body, options)
      .pipe(map(responseJson => MailyticaSmartResponse.fromJson(responseJson)));
  }

  public async login(
    loginRequest: LoginRequest,
    mailyticaAPIUrl: string
  ): Promise<string> {
    const username = loginRequest.username;
    const password = loginRequest.password;

    return await this.httpClient.post<string>(
      'https://' + mailyticaAPIUrl + '/login',
      {username, password},
      {observe: 'response', headers: this.loginHeaderToJson()}
    ).toPromise().then(response => {
      return response.headers.get('access-token');
    });
  }

  public async getAvailableScopes(mailyticaAPIUrl: string, accessToken: string): Promise<AvailableScopesHeader> {
    return await this.httpClient.get<AvailableScopesHeader>(
      'https://' + mailyticaAPIUrl + '/connect/availableScopes',
      {headers: this.accessHeaderToJson(accessToken)}
    ).toPromise();
  }

  public async submit(categoryModel: CategoryModel, mailyticaAPIUrl: string, acessToken: string): Promise<any> {

    return await this.httpClient.post<AccessToken>(
      'https://' + mailyticaAPIUrl + '/connect/accessToken',
      {categoryModelId: categoryModel.categoryModelId, clientId: 'outlook'},
      {headers: this.accessHeaderToJson(acessToken)}
    ).toPromise();
  }

  public postUserAction(
    message: Message,
    actionId: string,
    mailyticaAPIUrl: string,
    authorizationValue: string
  ): Observable<MailyticaSmartResponse> {

    const url = 'https://' + mailyticaAPIUrl + '/api/userAction';

    const body = {
      message: message,
      actionId: actionId
    };

    const options = {
      headers: this.smartResponseHeaderToJson(authorizationValue)
    };

    return this.httpClient
      .post<string>(url, body, options)
      .pipe(map(responseJson => MailyticaSmartResponse.fromJson(responseJson)));
  }

  public getDraftSmartResponses(
    message: Message,
    mailyticaAPIUrl: string,
    authorizationValue: string
  ): Observable<MailyticaSmartResponse> {
    return this.httpClient.post<any>(
      'https://' + mailyticaAPIUrl + '/api/classifyDraft',
      message.fallbackMessagetoJson(),
      {headers: this.smartResponseHeaderToJson(authorizationValue)}
    );
  }

  public predictMessage(
    inputMessage: string,
    inputCursorPosition: number,
    mailyticaAPIUrl: string,
    authorizationValue: string
  ): Promise<PredictionMessage> {
    return this.httpClient.post<PredictionMessage>(
      'https://' + mailyticaAPIUrl + '/api/predictMessage',
      {inputMessage: inputMessage, inputCursorPosition: inputCursorPosition},
      {headers: this.smartResponseHeaderToJson(authorizationValue)}
    ).toPromise();
    // return new Promise(resolve => resolve(new PredictionMessage(0, "Test", "", "Mock message")));
  }

  private loginHeaderToJson() {
    return new HttpHeaders().append('Content-Type', 'application/json');
  }

  private accessHeaderToJson(accessToken: string): HttpHeaders {
    return new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('access-token', accessToken);
  }

  private smartResponseHeaderToJson(authorizationValue: string) {
    return new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('Authorization', authorizationValue);
  }

  private goldStandardClassificationPayloadToJson(message: Message, goldStandardLabel: string) {
    return {
      mimeId: message.messageId,
      message: message.fallbackMessagetoJson(),
      goldStandardLabel: goldStandardLabel
    };
  }
}
