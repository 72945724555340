<div
  id="mailytica"
  *ngIf="!this.httpErrorResponse && this.mailboxMailyticaAccountMapping"
>

  <div
    class="mailyticaContainer"
    *ngIf="mailboxMailyticaAccountMapping.categoryModel.categoryModelId || this.authorizationValueConfiguredInManifest"
  >

    <div class="mailyticaContainer"
         *ngIf="this.mailyticaSmartResponse"
         [ngClass]="{'waiting': loadingAnimation == true}"
    >

      <div id="smartResponse">

        <app-topic class="section topic"
                   [topics]="this.mailyticaSmartResponse.topics"
                   [classificationLabel]="this.mailyticaSmartResponse.classification.label"
                   (selectedTopicChange)="this.updateSelectedTopic($event)"
                   (reloadMailyticaSmartResponse)="this.classifyMessage()"
        >
        </app-topic>

        <app-fields class="section"
                    *ngIf="this.mailyticaSmartResponse.availableFields.length > 0"
                    [availableFields]="this.mailyticaSmartResponse.availableFields"
                    [clientSettings]="this.mailyticaSmartResponse.clientSettings"
                    [metadata]="this.mailyticaSmartResponse.metadata"
                    (metaDataChangeEvent)="this.updateMetadata($event)"
        >
        </app-fields>

        <app-internal-note *ngIf="this.currentlyDisplayedSmartResponseProposal.note.length > 0"
                           class="section"
                           [internalNote]="this.currentlyDisplayedSmartResponseProposal.note"
        >
        </app-internal-note>

        <app-e-mail-template
          class="section"
          *ngIf="this.mailyticaSmartResponse.clientSettings.displaySmartResponsesStatus === 'active'"
          [smartResponseProposals]="this.mailyticaSmartResponse.smartResponseProposals"
          [selectedSmartResponseProposal]="this.currentlyDisplayedSmartResponseProposal"
          (updateSelectedSmartResponseProposal)="this.updateSelectedSmartResponseProposal($event)"
        >
        </app-e-mail-template>

        <app-proposal
          class="section"
          *ngIf="this.mailyticaSmartResponse.clientSettings.displaySmartResponsesStatus === 'active'"
          [smartResponseProposal]="this.currentlyDisplayedSmartResponseProposal"
          [isLocked]="this.mailyticaSmartResponse.isLocked"
          [styleMaxHeight]="28"
          (openNewMail)="this.checkForMetadataVariables()"
        >
        </app-proposal>
      </div>

      <div id="userActions">

        <button
          class="ms-Button ms-Button--primary"
          *ngIf="this.mailyticaSmartResponse.clientSettings.displaySmartResponsesStatus === 'active'"
          (click)="this.checkForMetadataVariables()"
          [disabled]="this.mailyticaSmartResponse.isLocked"
        >
          <span class="ms-Button-label">{{'REPLY-BUTTON' | translate}}</span>
        </button>

        <button
          *ngIf="this.mailyticaSmartResponse.clientSettings.finishMessageActivityStatus == 'active'"
          [disabled]="this.buttonFinishMessageDisabled" class="ms-Button ms-Button--primary"
          (click)="this.finishMessage()"
        >
          <span class="ms-Button-label">{{'E-MAIL-FINISHED' | translate}}</span>
        </button>

        <app-user-actions
          [availableUserActions]="this.mailyticaSmartResponse.availableUserActions"
          [metaData]="this.mailyticaSmartResponse.metadata"
          (sendUserActionRequest)="this.executeUserAction($event)"
        >
        </app-user-actions>

        <button
          *ngIf="this.mailyticaSmartResponse.clientSettings.changeSalutationStatus === 'active'"
          class="ms-Button ms-Button--secundary"
          data-toggle="modal"
          data-target="#salutationModal"
        >
          <span class="ms-Button-label">{{'CHANGE-SALUTATION-BUTTON' | translate}}</span>
        </button>

        <button
          *ngIf="this.mailyticaSmartResponse.clientSettings.changeLanguageStatus === 'active'"
          class="ms-Button ms-Button--secundary"
          data-toggle="modal"
          data-target="#languageModal"
        >
          <span class="ms-Button-label">{{'CHANGE-LANGUAGE-BUTTON' | translate}}</span>
        </button>

        <app-language-modal
          [selectedLanguage]="this.mailyticaSmartResponse.language"
          (changeSelectedLanguageEvent)="this.changeLanguage($event)"
        ></app-language-modal>
        <app-salutation-modal
          [salutation]="this.currentlyDisplayedSmartResponseProposal.salutation"
          (changeSelectedSalutationEvent)="this.changeSalutation($event)"
        ></app-salutation-modal>
      </div>
    </div>

    <ng-template #loading class="headline">
      <div class="d-flex justify-content-center">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden ms-font-l">{{'LOADING-DATA' | translate}}</span>
        </div>
      </div>
    </ng-template>

    <div class="loadingForeground" *ngIf="loadingAnimation">
      <div class="d-flex justify-content-center">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden ms-font-l">{{'LOADING-DATA' | translate}}</span>
        </div>
      </div>
      <span class="ms-font-xl loadingText">{{'LOADING-SMART-RESPONSE' | translate}}</span>
    </div>

  </div>

  <div
    *ngIf="!this.mailboxMailyticaAccountMapping.categoryModel.categoryModelId && !this.authorizationValueConfiguredInManifest"
    class="ms-font-l noData">{{'NO-DATA' | translate}}</div>

</div>

<div class="error" *ngIf="httpErrorResponse">
  <div class="errorHeadline">
    <i class="ms-Icon ms-Icon--ErrorBadge" aria-hidden="true"></i>
    <div class="ms-font-l errorStatus">{{'ERROR' | translate}}: {{httpErrorResponse.status}}</div>
  </div>

  <div class="errorBody" *ngIf="httpErrorResponse.status == 401 && authorizationValueConfiguredInManifest">
    <div class="ms-font-s errorText">{{'ERROR-401-ACCESS-TOKEN' | translate}}</div>
  </div>

  <div class="errorBody" *ngIf="httpErrorResponse.status == 401 && !authorizationValueConfiguredInManifest">
    <div class="ms-font-s errorText">{{'ERROR-401-CREDENTIALS' | translate}}</div>
  </div>

  <div class="errorBody" *ngIf="httpErrorResponse.status == 400">
    <div class="ms-font-s errorText">{{'ERROR-400' | translate}}</div>
  </div>

  <div class="errorBody" *ngIf="httpErrorResponse.status == 0">
    <div class="ms-font-s errorText">{{this.constants.name}}{{'ERROR-0' | translate}}</div>
  </div>

  <button class="ms-Button ms-Button--primary" (click)="restart()">
    <span class="ms-Button-label">{{'RELOAD-BUTTON' | translate}}</span>
  </button>
</div>
