export class ClientSetting {
  constructor(
    public changeLanguageStatus: string,
    public changeSalutationStatus: string,
    public displayEmptyTopicsStatus: string,
    public finishMessageActivityStatus: string,
    public finishMessageBySmartResponseStatus: string,
    public languageModelPredictionStatus: string,
    public respondWithDefaultSmartResponseButtonStatus: string,
    public respondWithSalutationButtonStatus: string,
    public syncCategoriesStatus: string,
    public transferRawMessageStatus: string,
    public displaySmartResponsesStatus: string = 'active'
  ) {
  }

  public static fromJson(entity: any): ClientSetting {

    return new ClientSetting(
      entity.changeLanguageStatus,
      entity.changeSalutationStatus,
      entity.displayEmptyTopicsStatus,
      entity.finishMessageActivityStatus,
      entity.finishMessagesBySmartResponseStatus,
      entity.languageModelPredictionStatus,
      entity.respondWithDefaultSmartResponseButtonStatus,
      entity.respondWithSalutationButtonStatus,
      entity.syncCategoriesStatus,
      entity.transferRawMessageStatus,
      entity.displaySmartResponsesStatus
    );
  }
}
