import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-topic',
  templateUrl: './topic.component.html',
  styleUrls: ['./topic.component.css']
})
export class TopicComponent {

  @Input() topics: Array<string>;
  @Input() classificationLabel: string;

  @Output() selectedTopicChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() reloadMailyticaSmartResponse: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  changeSelectedTopicEvent(selectedTopic: string) {

    if (selectedTopic !== this.classificationLabel) {

      this.classificationLabel = selectedTopic;
      this.selectedTopicChange.emit(selectedTopic);
    }
  }

  clickReloadIcon() {
    this.reloadMailyticaSmartResponse.emit();
  }
}
